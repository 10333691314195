export const isTestEnvironment = () => document.location.host === 'lk-test2.in-life.ru';

export const isProdEnvironmentInlife = () => document.location.host === 'lk.in-life.ru';

export const isProdEnvironmentSCB = () => document.location.host === 'service.sovcomlife.ru';

export const getCaptchaSiteKey = () => {
    if (isProdEnvironmentInlife()) {
        return 'ysc1_MmPKCyWrKMIaCwGpM2EuNrltQhTdklPP0HSPyIsE915f1129';
    }
    if (isProdEnvironmentSCB()) {
        return 'ysc1_vWcG8b5AD0Ri4wIyZluJCa9GH5AK3dzNczmain56b5e42b83';
    }
    if (isTestEnvironment()) {
        return 'ysc1_vh54qjx1TqbshSNJvMAZzEQaPSTpNegaCeiyi4Tv00a8686d';
    }
    return '';
};
